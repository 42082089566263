// Await/async imports
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Polyfills
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import 'custom-event-polyfill';
// Select 2
import 'select2/dist/js/select2.full.min';
// Other imports
import {DocumentationPathEvents} from '@/documentation/documentationPathEvents';
import {MercureEventHandler} from '@/mercure/MercureEventHandler';
import {NotificationEvents} from '@/notifications/types/NotificationEvents';
import {ProcessComponentPersonalSettings} from '@/types/ProcessFlow';
import {Fixes} from '@drenso/vue-frontend-shared';
import * as Sentry from '@sentry/browser';
import mitt, {Emitter} from 'mitt';
import moment from 'moment-timezone';

// Sentry
// Only bind when production mode is set
if (window.SENTRY_DSN) {
  console.log('Setting up default Sentry client');

  // Create the default sentry client
  Sentry.init({
    dsn: window.SENTRY_DSN,
    tunnel: '/_stun',
    release: window.SENTRY_RELEASE,
    environment: window.DEPLOYMENT_ENVIRONMENT,
    ignoreErrors: ['nis-cleanroom-auth://'],
  });

  Sentry.setUser({
    id: window.SENTRY_USERID,
    username: window.SENTRY_USERNAME,
    email: window.SENTRY_EMAIL,
    ip_address: window.SENTRY_IP_ADDRESS,
  });
}

// Register global mercure event handler
window.NIS_EVENTS = new MercureEventHandler();

// Register cross app event emitter
window.CROSS_APP_EMITTER = mitt();

// Register application fixes
Fixes.Select2.jQuery3dot6Fix();

$(() => {
  // Tooltips
  $('[data-toggle="tooltip"]').each(function() {
    // Call tooltip per element to prevent issues with Vue components
    $(this).tooltip();
  });

  // Bootstrap file input helper plugin
  if ($('.custom-file').length > 0) {
    import(/* webpackChunkName: "bs-custom-file-input" */ 'bs-custom-file-input')
      .then((bsCustomFileInput) => bsCustomFileInput.default.init());
  }

  // Refresh cookie if it exists
  const cookieExpiry = moment().add(1, 'year').toDate().toUTCString();
  if (-1 !== document.cookie.indexOf('SIDEBAR_COLLAPSED_V2=false')) {
    // Refresh false cookie on load, as true is the default
    document.cookie = `SIDEBAR_COLLAPSED_V2=false; path=/; expires=${cookieExpiry}`;
  }

  // Side menu collapsed state
  $(document)
    .on('shown.lte.pushmenu', () => {
      document.cookie = `SIDEBAR_COLLAPSED_V2=false; path=/; expires=${cookieExpiry}`;
    })
    .on('collapsed.lte.pushmenu', () => {
      document.cookie = `SIDEBAR_COLLAPSED_V2=true; path=/; expires=${cookieExpiry}`;
    });
});

/**
 * Array move function
 * @param from
 * @param to
 */
Array.prototype.move = function(from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

declare global {
  interface Window {
    APP_TIMEZONE: string;
    CROSS_APP_EMITTER: Emitter<DocumentationPathEvents & NotificationEvents & {
      'process-component-personal-settings': ProcessComponentPersonalSettings,
      'translations-updated': void
    }>;
    DEPLOYMENT_ENVIRONMENT: string;
    MERCURE_PUBLIC_URL: string;
    MERCURE_JWT_EXPIRY: string;
    NIS_EVENTS: MercureEventHandler;
    MAX_UPLOAD_SIZE?: string;
    MAX_UPLOAD_SIZE_LARGE?: string;
    OverlayScrollbars: (element: HTMLElement | null, options: Record<string, unknown>) => unknown;
    SENTRY_DSN: string;
    SENTRY_RELEASE: string;
    SENTRY_USERID?: string;
    SENTRY_USERNAME: string;
    SENTRY_EMAIL?: string;
    SENTRY_IP_ADDRESS: string;
  }

  interface Array<T> {
    move: (from: number, to: number) => T[];
  }
}
